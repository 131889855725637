<template>
  <div>
    <CRow>
      <CCol>
        <!-- Last Year Sales Widget -->
        <!-- <WidgetsDashboardTotalSalePurchase
          :color="'primary'"
          :icon="'cil-dollar'"
          :rightheader="getLastYearTotalSale()"
          :rightfooter="'TOTAL'"
          :leftheader="getLastYearAmountSale()"
          :leftfooter="'AMOUNT'"
          :title="'Last Year Sales'"
          :noCharts="true"
        /> -->
      </CCol>
      <CCol>
        <!-- Stock Status Widget -->
        <!-- <WidgetsDashboardTotalSalePurchase
          :color="'warning'"
          :icon="'cilTask'"
          :rightheader="dashboardData.stockStatus.inProgress.toString()"
          :rightfooter="'In Progress'"
          :leftheader="dashboardData.stockStatus.available.toString()"
          :leftfooter="'Available'"
          :title="'Stock Status'"
          :noCharts="true" />-->
      </CCol>
      <CCol>
        <!-- This Year Sales Widget -->
        <!-- <WidgetsDashboardTotalSalePurchase
          :color="'success'"
          :icon="'cil-dollar'"
          :rightheader="dashboardData.thisYearSales.total.toString()"
          :rightfooter="'TOTAL'"
          :leftheader="formatCurrency(dashboardData.thisYearSales.amount)"
          :leftfooter="'AMOUNT'"
          :title="'This Year Sales'"
          :noCharts="true"
        /> -->
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <WidgetsBarSimple
          :datasets="lastYearTopSellingModelsData"
          :labels="lastYearModelLabels"
          title="Last Year"
        >
        </WidgetsBarSimple>
      </CCol>
      <CCol>
        <WidgetsBarSimple
          :datasets="topSellingModelsData"
          :labels="modelLabels"
          title="This Year"
        >
        </WidgetsBarSimple>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DashboardApi from "../lib/dashboardApi";
// import WidgetsDashboardTotalSalePurchase from "./widgets/WidgetsDashboardTotalSalePurchase";
import WidgetsBarSimple from "./widgets/WidgetsBarSimple";

export default {
  name: "Dashboard",
  components: {
    // WidgetsDashboardTotalSalePurchase,
    WidgetsBarSimple,
    // MainChartExample,
    // WidgetsDropdown,
    // WidgetsBrand,
  },
  data() {
    return {
      api: new DashboardApi(),
      topSellingModelsData: [],
      modelLabels: [],

      dashboardData: {
        lastYearSales: {
          total: 0,
          amount: 0,
        },
        thisYearSales: {
          total: 0,
          amount: 0,
        },
        stockStatus: {
          available: 0,
          inProgress: 0,
        },
      },

      obj: {},
    };
  },
  computed: {
    computedTotalStockAvailable() {
      if (this.obj.totalStockAvailable !== undefined)
        return this.obj.totalStockAvailable.toString();
      else return "???";
    },
    computedTotalStockInProcess() {
      if (this.obj.totalStockInProcess !== undefined)
        return this.obj.totalStockInProcess.toString();
      else return "???";
    },
    computedTotalStockSoldThisYear() {
      if (this.obj.totalTotalStockSoldThisYear !== undefined)
        return this.obj.totalTotalStockSoldThisYear.toString();
      else return "???";
    },
    computedStockSoldThisYear() {
      return "Stock Sold In " + this.obj.thisYear;
    },
    computedTotalStockSoldLastYear() {
      if (this.obj.totalTotalStockSoldLastYear !== undefined)
        return this.obj.totalTotalStockSoldLastYear.toString();
      else return "???";
    },
    computedStockSoldLastYear() {
      return "Stock Sold In " + this.obj.lastYear;
    },
  },
  mounted() {
    var self = this;
    self.resetObj();
  },
  methods: {
    getLastYearTotalSale() {
      try {
        return dashboardData.lastYearSales.total.toString();
      } catch (error) {
        return "N/A";
      }
    },
    getLastYearAmountSale() {
      try {
        return dashboardData.lastYearSales.amount.toFixed(2);
      } catch (error) {
        return "N/A";
      }
    },
    formatCurrency(value) {
      return `RM ${value.toFixed(2)}`;
    },
    resetObj() {
      var self = this;
      self.api
        .getTop()
        .then((response) => {
          console.log(response.result);
          self.dashboardData = response.result;
          console.log(self.dashboardData);

          // Process the top-selling models data
          this.modelLabels = self.dashboardData.topSellingModels.map(
            (model) => model.name
          );
          this.topSellingModelsData = [
            {
              label: "Units Sold",
              backgroundColor: "#2eb85c",
              data: self.dashboardData.topSellingModels.map(
                (model) => model.unitsSold
              ),
            },
          ];

          // Process the last year top-selling models data
          this.lastYearModelLabels =
            self.dashboardData.lastYearTopSellingModels.map(
              (model) => model.name
            );
          this.lastYearTopSellingModelsData = [
            {
              label: "Units Sold",
              backgroundColor: "#321fdb",
              data: self.dashboardData.lastYearTopSellingModels.map(
                (model) => model.unitsSold
              ),
            },
          ];
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
  },
};
</script>
