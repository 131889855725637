

class DashboardApi {
    // get() {
    //     var url = apiUrl + 'dashboards';
    //     return api.call('get', url)
    //         .then(({ data }) => {
    //             return data
    //         });
    // }

    getTop() {
        var url = apiUrl + 'dashboards/top';
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }

}

export default DashboardApi;