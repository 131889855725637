<template>
  <CCol>
    <CRow>
      <CCol
        ><strong>{{ title }}</strong></CCol
      >
    </CRow>
    <CRow
      ><CCol>
        <CCard>
          <CCardBody>
            <CChartBar 
            :datasets="datasets" 
            :labels="labels"
             />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CCol>
</template>
  
  <script>
import { CChartBar } from "@coreui/vue-chartjs";

export default {
  name: "WidgetsBarSimple",
  components: { CChartBar },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "TITLE",
    },
  
    
  },
};
</script>
  